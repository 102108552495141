import React from "react";
import instine from "../../../assets/homepage/man-img.svg";
import "../Homepage.css";
// import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";

const listData = [
  {
    number: 130,
    status: "Very Superior",
    bgColor:
      "linear-gradient(90deg, #BF3D65 0.29%, rgba(20, 26, 32, 0.00) 100.66%)",
    text: "and above",
  },
  {
    number: 120,
    status: "Superior",
    bgColor:
      "linear-gradient(90deg, #F9A537 0.29%, rgba(20, 26, 32, 0.00) 100.66%)",
    text: "to",
    number2: 129,
  },
  {
    number: 109,
    status: "High average",
    bgColor:
      "linear-gradient(90deg, #384A87 0.29%, rgba(20, 26, 32, 0.00) 100.66%)",
    text: "to",
    number2: 119,
  },
  {
    number: 90,
    status: "Average",
    bgColor:
      "linear-gradient(90deg, #52B95D 0.29%, rgba(20, 26, 32, 0.00) 100.66%) ",
    text: "to",
    number2: 109,
  },
  {
    number: 80,
    status: "Low average",
    bgColor:
      "linear-gradient(90deg, #6CC9C8 0.29%, rgba(20, 26, 32, 0.00) 100.66%)",
    text: "to",
    number2: 89,
  },
  {
    number: 70,
    status: "Border Line",
    bgColor:
      "linear-gradient(90deg, #9ECC4E 0.29%, rgba(20, 26, 32, 0.00) 100.66%)",
    text: "to",
    number2: 79,
  },
  {
    number: 69,
    status: "below extremely low",
    bgColor:
      "linear-gradient(90deg, #BF3D65 0.29%, rgba(20, 26, 32, 0.00) 100.66%)",
    text: "and below",
  },
];
const GraphData = [
  {
    number1: 130,
    bgColor: "#B73C62",
    width: "60%",
    number2: 170,
  },
  {
    number1: 120,
    bgColor: "#F1A036",
    width: "55%",
    number2: 129,
  },
  {
    number1: 109,
    bgColor: "#364781",
    width: "50%",
    number2: 119,
  },
  {
    number1: 90,
    bgColor: "#50B45C",
    width: "45%",
    number2: 109,
  },
  {
    number1: 80,
    bgColor: "#69C4C4",
    width: "40%",
    number2: 89,
  },
  {
    number1: 70,
    bgColor: "#98C54C",
    width: "35%",
    number2: 79,
  },
  {
    number1: 69,
    bgColor: "#C64C4D",
    width: "30%",
    number2: 30,
  },
];

const IQScoreComponent = () => {
  return (
    <>
      <div className="IQScore-mean-section">
        <div className="container">
          <div className="row">
            <div className="col col-12">
              <h2 className="heading-text text-white">
                What does my IQ score mean?
              </h2>
              <p className="text-desc" style={{ color: "#c8c8c8" }}>
                A score that tells one how “bright” a person is compared to
                others.
              </p>
            </div>
          </div>
          <div className="row g-5 align-items-center justify-content-md-between justify-content-center px-lg-5">
            <div className="col-lg-5 col-md-6 col-12">
              <div className="score-graph">
                <h4 className="md-heading">IQ Score Graph</h4>
                <ul className="score-ul ps-0 mb-0">
                  {listData.map((list, i) => {
                    return (
                      <li
                        key={i}
                        style={{
                          background: `${list?.bgColor}`,
                        }}
                      >
                        <h6>
                          <span>{list?.number} </span>
                          {list?.text}
                          <span> {list?.number2}</span>
                        </h6>
                        <p>{list?.status}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="man-chip">
                <div>
                  <h6>Einstein IQ = 160+</h6>
                  <p>What about Yours? </p>
                </div>
                <div className="img-out">
                  <img src={instine} alt="instine" />
                </div>
              </div>
              <div className="score-bar-chart">
                <h4 className="md-heading mb-md-4 mb-3 p-0">IQ Score Graph</h4>
                <ul className="bar-ul ps-0">
                  {GraphData.map((list, i) => {
                    return (
                      <li key={i}>
                        <p className="rank">{list?.number1}</p>
                        <div className="bar">
                          <div
                            className="filled"
                            style={{
                              background: `${list?.bgColor}`,
                              width: `${list?.width}`,
                            }}
                          ></div>
                        </div>
                        <p className="rank ps-2">{list?.number2}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          {/* <div className="row mt-4">
            <div className="col col-12 col-lg-4 mt-2">
              <div className="score-box" style={{ background: "#32BCA3" }}>
                <h3>Very Superior</h3>
                <p>130 and above</p>
              </div>
            </div>
            <div className="col col-12 col-lg-4 mt-2">
              <div className="score-box" style={{ background: "#40505F" }}>
                <h3>Superior</h3>
                <p>120 to 129</p>
              </div>
            </div>
            <div className="col col-12 col-lg-4 mt-2">
              <div className="score-box" style={{ background: "#2685CA" }}>
                <h3>High Average</h3>
                <p>109 to 119</p>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col col-12 col-lg-4 mt-2">
              <div className="score-box" style={{ background: "#FF7A00" }}>
                <h3>Average</h3>
                <p>90 to 109</p>
              </div>
            </div>
            <div className="col col-12 col-lg-4 mt-2">
              <div className="score-box" style={{ background: "#519DD5" }}>
                <h3>Low Average</h3>
                <p>80 and 89</p>
              </div>
            </div>
            <div className="col col-12 col-lg-4 mt-2">
              <div className="score-box" style={{ background: "#32BCA3" }}>
                <h3>Borderline</h3>
                <p>70 and 79</p>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col col-12 col-lg-4 mt-2">
              <div className="score-box" style={{ background: "#2685CA" }}>
                <h3>below Extremely Low</h3>
                <p>69 and below</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default IQScoreComponent;
