import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import LiveDot from "../../../assets/homepage/dot.gif";
import useAuth from "../../../hooks/useAuth";
import "../Homepage.css";
import VisitorCount from "../VisitorCount";

const IQCountComponent = () => {
  const { visitorCount, setIqTestFinalResult } = useAuth();
  const [testList, setTestList] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL_API}/api/iq-bank-list-count`)
      .then((response) => response.json())
      .then((data) => setTestList(data));
  }, []);
  const [testavg, setTestAvg] = useState([]);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL_API}/api/iq-test-average-result`)
      .then((response) => response.json())
      .then((data) => setTestAvg(data));
  }, []);

  return (
    <>
      <div className="container-fluid countBg">
        <div className="container">
          <div className="row">
            <div className="col col-12 col-md-4">
              <div className="test-count text-center">
                <h2 className="mt-2">Total number of tests</h2>
                <h4>
                  {/* 7,383,540 */}
                  <CountUp end={testList} duration={5} separator="," />
                </h4>
              </div>
            </div>
            <div className="col col-12 col-md-4">
              <div className="test-count text-center">
                <h2 className="mt-2">Real-Time User</h2>
                <VisitorCount></VisitorCount>
                <h4>
                  <div className="liveDot" />
                  <CountUp
                    end={visitorCount}
                    duration={3}
                    className="ps-md-4"
                  />
                  {/* &nbsp; 63 */}
                </h4>
              </div>
            </div>
            <div className="col col-12 col-md-4">
              <div className="test-count text-center mb-0">
                <h2 className="mt-2">Today's average IQ</h2>
                <h4>
                  <CountUp end={testavg} decimals={2} duration={2} />
                  {/* 106.25 */}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IQCountComponent;
