import React, { useEffect, useState } from "react";
import "../Homepage.css";
import book from "../../../assets/homepage/notes.svg";
import flag8 from "../../../assets/homepage/flag-8.svg";
import flag9 from "../../../assets/homepage/flag-9.svg";
// import ReactCountryFlag from "react-country-flag";
const IQResListsComponent = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [testTakeListFlag, setTestTakeListFlag] = useState([]);

  function sortByIQLevel(array) {
    array.sort((a, b) => {
      if (a.testResultData.newResult < b.testResultData.newResult) return 1;
      if (a.testResultData.newResult > b.testResultData.newResult) return -1;
      return 0;
    });

    return array;
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_URL_API}/api/iq-test-list-top-ten`)
      .then((response) => response.json())
      .then((data) => {
        setTestTakeListFlag(sortByIQLevel(data.enhancedResults));
      });
  }, []);

  const tableData = [1, 2, 3, 4, 5];
  return (
    <>
      <div className="response-list">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="res-title">
                <h1 className="heading-text text-white pe-lg-5 pe-3 position-relative d-inline-block">
                  Latest 10 <span> IQ Results</span>
                  <img src={book} alt="img-book" className="img-book" />
                </h1>
                <p className="text-desc text-white">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolo
                </p>
              </div>
            </div>
          </div>
          {/* <div className="row mt-3">
            <div className="col col-12">
              <div className="res-table">
                <table className="table table-responsive table-borderless table-top-ten">
                  <thead></thead>
                  <tbody>
                    {testTakeListFlag.map((flag) => (
                      <tr key={flag._id}>
                        <td>
                          <ReactCountryFlag
                            className="emojiFlag"
                            countryCode={flag.countryCode}
                            svg
                            style={{
                              height: "75px",
                              width: "135px",
                              lineHeight: "2em",
                            }}
                            aria-label={flag.countryCode}
                          />
                        </td>
                        <td>{flag.firstname}:&nbsp;</td>
                        <td>IQ : {flag.testResultData?.newResult}</td>
                        <td>
                          {new Date(flag.createdAt).toISOString().substr(0, 10)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
          <div className="row mt-3 justify-content-md-between">
            <div className="col-12 col-lg-5">
              <div className="res-table">
                <table className="table table-responsive  table-top-ten mb-0">
                  <thead></thead>
                  <tbody>
                    {tableData.map((data, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <td colSpan={4}></td>
                          </tr>
                          <tr>
                            <td>
                              <img src={flag8} alt="flag" />
                            </td>
                            <td>Piyush</td>
                            <td>IQ : 101</td>
                            <td>8/15/17 </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="res-table">
                <table className="table table-responsive  table-top-ten mb-0">
                  <thead></thead>
                  <tbody>
                    {tableData.map((data, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <td colSpan={4}></td>
                          </tr>
                          <tr>
                            <td>
                              <img src={flag9} alt="flag" />
                            </td>
                            <td>Piyush</td>
                            <td>IQ : 101</td>
                            <td>8/15/17 </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IQResListsComponent;
