import React from "react";
import { Link } from "react-router-dom";

export default function IQDownload() {
  return (
    <div className="download-wrapper">
      <h2>
        Download Your <span>IQ Certificate</span>
      </h2>
      <button className="btn-orange">Download</button>
    </div>
  );
}
