import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import IqLogo from "../../../assets/iqcosmos.svg";
import hamburgur from "../../../assets/homepage/hamburgar-icon.svg";
import close from "../../../assets/homepage/close-icon.svg";
import "../Homepage.css";
const TopHeaderComponent = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };
  return (
    <>
      <header>
        <div className="container">
          <div className="row py-2 align-items-center">
            <div className="col col-4 col-sm-4 col-md-4 text-center">
              <a href="/" className="img-wrapper">
                <img src={IqLogo} alt="iqLogo" className="iq-mbl-logo" />
              </a>
            </div>
            <div className="col col-8 col-sm-8 col-md-8  text-end">
              <div className="d-flex align-items-center justify-content-end">
                <Link
                  to={""}
                  className="d-inline-block ff-inter text-white me-md-3 me-2 download-text"
                >
                  Download IQ Certificate
                </Link>
                <Link
                  to={"/about-iq"}
                  className="iqBtn mblStart d-inline-block"
                >
                  Start IQ Test
                </Link>
                <div
                  className="menu-icon-home ms-sm-3 ms-2"
                  onClick={handleMenuToggle}
                >
                  {showMenu ? (
                    <img src={close} alt="close-icon" />
                  ) : (
                    <img src={hamburgur} alt="hamburgur" />
                  )}
                </div>
                <div className={`menu-item ${showMenu ? "open" : "hide-item"}`}>
                  <ul className="ps-0 mb-0">
                    <li>
                      <NavLink to={"/about-iq"} activeclassname="true">
                        About IQ
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/contact-us"} activeclassname="true">
                        Contact us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/eview"} activeclassname="true">
                        Review
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/terms"} activeclassname="true">
                        Terms & Conditions
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/privacy"} activeclassname="true">
                        Privacy policy
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default TopHeaderComponent;
