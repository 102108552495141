import React from "react";
import PenDraw from "../../../assets/homepage/pen-draw.png";
import "../Homepage.css";

const IQKnowComponent = () => {
  return (
    <>
      <div className="why-section">
        <div className="container">
          <div className="row row-eq-height">
            <div className="col-12">
              <div className="text-center">
                <h2 className="heading-text">
                  Why should
                  <span> We know IQ?</span>
                </h2>
                <p className="text-desc mt-3">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolo
                </p>
              </div>
            </div>
            <div className="col col-12 col-lg-5 iqnk ">
              <div className="iq-country-slider">
                <iframe
                  title="YouTube video player"
                  width="100%"
                  src="https://www.youtube.com/embed/0kHWBj1und4"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="col col-12 col-lg-7 iqnk">
              <ul>
                <li className="knowp">
                  The average human has an IQ of 85 to 115. Low scores don’t
                  define students’ futures. It just gives an idea of how
                  proficient a person might be at logical tasks. However, In
                  general, people who understand the world they live in better
                  will avoid things that might endanger their lives.
                </li>
                <li className="knowp">
                  IQ scores can serve as a helpful guideline for one’s academic
                  and professional choices. Studies have shown that IQ doesn’t
                  automatically translate into wealth, success, or happiness.
                  What it can do is measure a person’s strengths and weaknesses
                  and give a better- and well-informed idea of how to proceed in
                  certain areas of life.
                </li>
                <li className="knowp">
                  So If you don’t know your IQ test scores, you should consider
                  trying a test just to get another bit of info about how your
                  mind works.
                </li>
                <li className="knowp">
                  So If you don’t know your IQ test scores, you should consider
                  trying a test just to get another bit of info about how your
                  mind works.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IQKnowComponent;
