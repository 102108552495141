import React from "react";
import "../Homepage.css";
import lamp from "../../../assets/homepage/light-lamp.svg";
import mind from "../../../assets/homepage/mind-img.svg";
import WorkList1 from "../../../assets/homepage/work-list-1.svg";
import WorkList2 from "../../../assets/homepage/work-list-2.svg";
import WorkList3 from "../../../assets/homepage/work-list-3.svg";
import WorkList4 from "../../../assets/homepage/work-list-4.svg";
import { Link } from "react-router-dom";

const HowCard = [
  {
    counter: "01",
    img: WorkList1,
    listName: "Get Ready",
  },
  {
    counter: "02",
    img: WorkList2,
    listName: "Complete the test",
  },
  {
    counter: "03",
    img: WorkList3,
    listName: "Share & Compare your results with your friends",
  },
  {
    counter: "04",
    img: WorkList4,
    listName: "Get your printable IQ Score certification",
  },
];
const HowItWorksComponent = () => {
  return (
    <>
      <div className="py-5">
        <div className="container">
          <div className="row mt-md-4">
            <div className="col-12 col-lg-6 col-xl-5 mt-3 text-lg-start text-center">
              <div className="how-section">
                <h2 className="heading-text d-inline-block position-relative">
                  How it
                  <span> works?</span>
                  <img src={lamp} alt="" className="icon-lamp" />
                </h2>
                <p className="text-desc mt-2 ff-inter">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit.
                </p>
                <div className="how-txtLine"></div>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-7 mt-3">
              <div className="d-flex align-items-center justify-content-lg-end justify-content-lg-start justify-content-center h-100 mb-lg-0 mb-4">
                <Link to={"/about-iq"} className="iqBtn3 ff-inter">
                  Check your IQ
                </Link>
              </div>
            </div>
          </div>
          <div className="row justify-content-between mind-sec">
            <div className="col col-12 col-lg-5 mt-3">
              <div className="img-out">
                <img src={mind} alt="mind" className="w-100" />
              </div>
            </div>
            <div className="col col-12 col-lg-6 mt-3">
              <div className="row gy-5 gx-3">
                {HowCard.map((data, i) => {
                  return (
                    <div className="col-sm-6 col-12" key={i}>
                      <div className="work-card">
                        <div className="counter">
                          <h4>{data.counter}</h4>
                        </div>
                        <div className="img-out">
                          <img src={data.img} alt="list 1" />
                        </div>
                        <p className="text mt-2">{data.listName}</p>
                      </div>
                    </div>
                  );
                })}

                {/* <div className="col-md-6">
                  <div className="work-card">
                    <img src={WorkList2} alt="list 2" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="work-card">
                    <img src={WorkList3} alt="list 3" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="work-card">
                    <img src={WorkList4} alt="list 4" />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorksComponent;
