import React from "react";

const SignUp = () => {
  return (
    <>
    </>
  );
};

export default SignUp;
