import React from "react";
import flag1 from "../../../assets/homepage/flag-1.svg";
import flag2 from "../../../assets/homepage/flag-2.svg";
import flag3 from "../../../assets/homepage/flag-3.svg";
import gold from "../../../assets/homepage/gold-medal.png";
import silver from "../../../assets/homepage/silver-medal.png";
import bronz from "../../../assets/homepage/bronz-medal.png";

const IQScoreCardComponent = () => {
  return (
    <>
      <div className="score-card-main">
        <div className="container">
          <div className="inner-wrapper">
            <div className="row justify-content-center">
              <div className="col-md-4 col-6 mb-3">
                <div className="score-card gold">
                  <div className="d-flex align-items-center mb-2">
                    <div className="flag-out">
                      <img src={flag1} alt="flag" />
                    </div>
                    <div className="rank-details text-center ps-3">
                      <p>IQ Score</p>
                      <h5>140</h5>
                    </div>
                    <img src={gold} alt="medal" className="medal" />
                  </div>
                  <div className="d-flex align-items-center name-details pe-4">
                    <h6>Sarth</h6>
                    <span>12/02/23</span>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-6 mb-3">
                <div className="score-card silver">
                  <div className="d-flex align-items-center mb-2">
                    <div className="flag-out">
                      <img src={flag2} alt="flag" />
                    </div>
                    <div className="rank-details text-center ps-3">
                      <p>IQ Score</p>
                      <h5>126</h5>
                    </div>
                    <img src={silver} alt="medal" className="medal" />
                  </div>
                  <div className="d-flex align-items-center name-details pe-4">
                    <h6>Piyush</h6>
                    <span>12/02/23</span>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-6 mb-3">
                <div className="score-card bronz">
                  <div className="d-flex align-items-center mb-2">
                    <div className="flag-out">
                      <img src={flag3} alt="flag" />
                    </div>
                    <div className="rank-details text-center ps-3">
                      <p>IQ Score</p>
                      <h5>120</h5>
                    </div>
                    <img src={bronz} alt="medal" className="medal" />
                  </div>
                  <div className="d-flex align-items-center name-details pe-4">
                    <h6>Heena</h6>
                    <span>12/02/23</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IQScoreCardComponent;
