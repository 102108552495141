import React from "react";
import flag4 from "../../../assets/homepage/flag-4.svg";
import flag5 from "../../../assets/homepage/flag-5.svg";
import flag6 from "../../../assets/homepage/flag-6.svg";
import flag7 from "../../../assets/homepage/flag-7.svg";

const UserCardsList = [
  {
    img: flag4,
    number: 115,
    userName: "Mehul",
    date: "12/02/23",
  },
  {
    img: flag5,
    number: 108,
    userName: "Piyush",
    date: "12/02/23",
  },
  {
    img: flag6,
    number: 95,
    userName: "Bhargav",
    date: "12/02/23",
  },
  {
    img: flag7,
    number: 65,
    userName: "Mehul",
    date: "12/02/23",
  },
  {
    img: flag7,
    number: 65,
    userName: "Mehul",
    date: "12/02/23",
  },
  {
    img: flag4,
    number: 115,
    userName: "Mehul",
    date: "12/02/23",
  },
  {
    img: flag5,
    number: 105,
    userName: "Piyush",
    date: "12/02/23",
  },
];

const UserCards = () => {
  return (
    <div className="container">
      <div className="slider-outside">
        <div className="slider-wrapper">
          {UserCardsList.map((data, i) => {
            return (
              <div className="score-user-card" key={i}>
                <div className="d-flex align-items-center mb-2">
                  <div className="flag-out">
                    <img src={data.img} alt="flag" />
                  </div>
                  <div className="rank-details text-center ps-3">
                    <p>IQ Score</p>
                    <h5>{data.number}</h5>
                  </div>
                </div>
                <div className="d-flex align-items-center name-details">
                  <h6>{data.userName}</h6>
                  <span>{data.date}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default UserCards;
